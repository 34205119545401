import { graphql, Link, useStaticQuery } from 'gatsby'
import React, { useState } from 'react'
import {
  ContentfulPortfolioItem,
  HomePageQueryQuery,
} from '../generated/graphql'
import { PortfolioItem } from './PortfolioItem'
import { Tag } from './Tag'

export const Portfolio = ({
  content,
}: {
  content: HomePageQueryQuery['content']
}) => {
  const [filter, setFilter] = useState<string | undefined>(undefined)
  return (
    <>
      <div className="pb-4 border-b border-gray-800 mb-4 w-full">
        <Tag
          isActive={filter === undefined}
          onClick={() => setFilter(undefined)}
        >
          All
        </Tag>
        {content!.portfolioCategories!.map((category) => (
          <Tag
            key={category!.contentful_id}
            isActive={category!.categoryName === filter}
            onClick={() => setFilter(category!.categoryName || undefined)}
          >
            {category!.categoryName}
          </Tag>
        ))}
      </div>
      {content!
        .portfolioItems!.filter(
          (el) =>
            !filter ||
            el!.portfolioCategories?.some((it) => it?.categoryName === filter)
        )
        .map((item) =>
          item!.link ? (
            <a
              key={item?.id}
              href={item!.link}
              target="_blank"
              className="no-underline w-full text-white"
            >
              <PortfolioItem
                data={item! as Partial<ContentfulPortfolioItem>}
                key={item!.id}
              />
            </a>
          ) : (
            <PortfolioItem
              data={item! as Partial<ContentfulPortfolioItem>}
              key={item!.id}
            />
          )
        )}
    </>
  )
}
