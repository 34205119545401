import React from 'react'
import { ErrorComponent } from '../components/Error'
import Seo from '../components/seo'
import Navigation from '../components/NavBar'
import { PortfolioPageQueryQuery } from '../generated/graphql'
import { Portfolio } from '../components/Portfolio'
import { WindowLocation } from '@reach/router'

export const PortfolioPageContainer = ({
  data,
  location,
  locale = 'en-US',
}: {
  data: PortfolioPageQueryQuery
  location: WindowLocation
  locale?: string
}) => {
  if (!data.content) {
    return <ErrorComponent message="Unable to load Page" />
  }
  return (
    <div className="min-h-screen relative bg-bg dark">
      <Seo title={`Puzzle Ventures - Portfolio`} />
      <main className="h-full relative flex flex-col items-center">
        <div className="flex flex-col h-full w-full max-w-6xl">
          <Navigation location={location} locale={locale} />
          <div className="flex flex-col flex-auto pb-8 w-full">
            <div className="flex-auto flex flex-col items-center md:flex-row-reverse h-full relative w-full">
              <div className="fixed left-0 top-1/3 flex-auto flex-col items-center w-1/2 pointer-events-none z-10 hidden md:flex-row md:flex">
                <div className="flex-1 flex items-end">
                  <img
                    alt="puzzle-ventures-gif"
                    src={data.content.mainImage?.file?.url!}
                    className="object-contain w-full py-8 px-8 md:py-0 max-h-96"
                  />
                </div>
              </div>
              <div className="flex-auto flex flex-col w-full items-center md:flex-row h-full container max-w-xl">
                <div className="text-white max-w-full items-center flex-col relative h-full px-6 md:pr-8 md:items-start">
                  <div className="md:hidden">
                    <img
                      alt="puzzle-ventures-gif"
                      src={data.content.mainImage?.file?.url!}
                      className="object-cover w-full py-8 px-8 md:py-0"
                    />
                  </div>
                  <div
                    className="min-h-full flex flex-col items-start align-middle justify-center pb-32"
                    id="portfolio"
                  >
                    <Portfolio content={data.content} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  )
}

