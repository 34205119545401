import React from 'react'
import { graphql, PageProps } from 'gatsby'
import { ErrorComponent } from '../components/Error'
import { PortfolioPageQueryQuery } from '../generated/graphql'
import { PortfolioPageContainer } from '../containers/portfolio'

const PortofolioPage = ({
  data,
  location,
}: PageProps<PortfolioPageQueryQuery, { id: string }>) => {
  if (!data.content) {
    return <ErrorComponent message="Unable to load Page" />
  }
  return <PortfolioPageContainer data={data} location={location} />
}
// This is the page query that connects the data to the actual component. Here you can query for any and all fields
// you need access to within your code. Again, since Gatsby always queries for `id` in the collection, you can use that
// to connect to this GraphQL query.
export const query = graphql`
  query PortfolioPageQuery {
    content: contentfulHomePage(
      contentfulid: { eq: "homepage" }
      node_locale: { eq: "en-US" }
    ) {
      mainImage {
        file {
          url
        }
      }
      portfolioTitle
      writingTitle
      headerTextBigOffset
      portfolioItems {
        contentful_id
        portfolioCategories {
          categoryName
        }
        id
        link
        icon {
          file {
            url
          }
        }
        description
        name {
          name
        }
      }

      portfolioCategories {
        contentful_id
        categoryName
      }
    }
  }
`
export default PortofolioPage
