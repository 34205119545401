import React from 'react'

export const Tag = ({
  children,
  isActive = false,
  onClick,
}: {
  children: React.ReactNode
  isActive?: boolean
  onClick?: () => void
}) => {
  return (
    <button
      onClick={onClick}
      className={`${
        isActive ? 'bg-accent text-black' : 'border-accent border'
      } px-4 ml-2 py-0.5 rounded-full my-1 text-sm`}
    >
      {children}
    </button>
  )
}
