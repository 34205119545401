import React from 'react'

import {
  ContentfulPortfolioItem,
  HomePageQueryQuery,
} from '../generated/graphql'
import { Tag } from './Tag'

export const PortfolioItem = ({
  data,
}: {
  data: Partial<ContentfulPortfolioItem>
}) => {
  return (
    <div className="flex pb-3 pt-3 border-b-2 border-gray-500 w-full">
      <img
        src={data.icon?.file?.url!}
        className="object-contain min-h-16 max-w-[56px]"
      />
      <div className="flex flex-1 flex-col ml-4 ">
        <div className="flex flex-row justify-end items-center">
          <h3 className="mb-0 flex-1 pb-0 text-base">{data.name?.name}</h3>
          {(data.portfolioCategories || []).map((category, i) => (
            <Tag key={category?.id ?? i}>{category?.categoryName!}</Tag>
          ))}
        </div>
        <p className="text-sm">{data.description}</p>
      </div>
    </div>
  )
}
